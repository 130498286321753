exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-base-tsx": () => import("./../../../src/pages/clients-base.tsx" /* webpackChunkName: "component---src-pages-clients-base-tsx" */),
  "component---src-pages-electronic-journal-tsx": () => import("./../../../src/pages/electronic-journal.tsx" /* webpackChunkName: "component---src-pages-electronic-journal-tsx" */),
  "component---src-pages-employee-schedule-tsx": () => import("./../../../src/pages/employee-schedule.tsx" /* webpackChunkName: "component---src-pages-employee-schedule-tsx" */),
  "component---src-pages-financial-accounting-tsx": () => import("./../../../src/pages/financial-accounting.tsx" /* webpackChunkName: "component---src-pages-financial-accounting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-management-company-tsx": () => import("./../../../src/pages/management-company.tsx" /* webpackChunkName: "component---src-pages-management-company-tsx" */),
  "component---src-pages-mobile-access-tsx": () => import("./../../../src/pages/mobile-access.tsx" /* webpackChunkName: "component---src-pages-mobile-access-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-payroll-tsx": () => import("./../../../src/pages/payroll.tsx" /* webpackChunkName: "component---src-pages-payroll-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-scheduling-tsx": () => import("./../../../src/pages/scheduling.tsx" /* webpackChunkName: "component---src-pages-scheduling-tsx" */),
  "component---src-pages-social-network-authorization-tsx": () => import("./../../../src/pages/social-network-authorization.tsx" /* webpackChunkName: "component---src-pages-social-network-authorization-tsx" */),
  "component---src-pages-statistics-and-analytics-tsx": () => import("./../../../src/pages/statistics-and-analytics.tsx" /* webpackChunkName: "component---src-pages-statistics-and-analytics-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-theming-tsx": () => import("./../../../src/pages/theming.tsx" /* webpackChunkName: "component---src-pages-theming-tsx" */)
}

